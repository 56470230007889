import React from 'react'
import CallToAction from '../components/CallToAction'

export default function corporateContactPage() {
  return (
    <main className="corporate-contact">
      <div className="page-section">
        <div className="page-section-content">
          <h1>Corporate Contact</h1>
        </div>
        <div className="page-section-content general-inquiries">
          <h3>For general inquiries and corporate communications:</h3>
        </div>
        <div className="page-section-content contact-info">
          <div>
            <h4>Address</h4>
            <p>47 Summit Ave</p>
            <p>Summit, NJ 07901, USA</p>
          </div>
          <div>
            <h4>Phone</h4>
            <p>(646) 417-8143</p>
          </div>
          <div>
            <h4>Email &ndash; General Info</h4>
            <p>info@adheretech.com</p>
          </div>
        </div>
      </div>
      <div className="page-section">
        <div className="page-section-content call-to-action-grid">
          <CallToAction
            headerText="Partner with us"
            content="Ready to talk about partnering to improve adherence?"
            linkText="Contact Us"
            linkUrl="/contact-us"
            color="blue"
          />
          <CallToAction
            headerText="Have questions about your Aidia Smart Bottle?"
            content="Our support specialists are here to help."
            linkText="Contact an Aidia Specialist"
            linkUrl="/patient-support"
            color="blue"
          />
        </div>
      </div>
    </main>
  )
}
